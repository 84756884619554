import React from "react";
import styled from "styled-components";
import ComingSoonButton from "./ComingSoonButton";
import facebookIcon from "../images/facebook.svg";
import twitterIcon from "../images/twitter.svg";
import instagramIcon from "../images/instagram.svg";
import linkedinIcon from "../images/linkedin.svg";

const Footer = () => {
  return (
    <Wrapper>
      <div className="container container--footer">
        <div className="footer-column">
          <ComingSoonStyled />

          <ul className="footer-links">
            <li>
              <a href="mailto:zivanovic.b1@hotmail.com">Contact Us</a>
            </li>

            <li>
              <a href="http://angel.co/">We're Hiring!</a>
            </li>
          </ul>
        </div>

        <div className="footer-row">
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                className="icon icon--twitter"
                href="https://twitter.com/listtyapp"
              ></a>
            </li>

            <li>
              <a
                target="_blank"
                className="icon icon--instagram"
                href="https://www.instagram.com/listty/"
              ></a>
            </li>

            <li>
              <a
                className="icon icon--linkedin"
                href="https://www.linkedin.com/company/listty"
                target="_blank"
              ></a>
            </li>
          </ul>

          <span className="dzoni-pent">
            🌎 &nbsp; © 2021 Listty, Belgrade, Serbia
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 55px 0 70px 0;
  background: rgba(28, 30, 33, 0.7);

  @media screen and (max-width: 1085px) {
    margin-top: 0;
  }

  .container {
    &--footer {
      padding-top: 0 !important;
      display: flex;
      flex-flow: column nowrap;
    }
  }

  .footer-column {
    height: 95px;
    max-width: 250px;
    margin: 0 auto 70px auto;
    display: flex;
    flex-flow: column nowrap;

    .footer-links {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 5px;
    }
  }

  .footer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 1085px) {
      flex-flow: column nowrap;
    }

    ul {
      display: flex;

      @media screen and (max-width: 1085px) {
        margin-bottom: 25px;
      }

      > li {
        width: 18px;
        height: 18px;
        margin-right: 25px;
        &:last-of-type {
          margin-right: 0;
        }
      }

      .icon {
        display: block;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: cover;

        &--facebook {
          background-image: url(${facebookIcon});
        }

        &--instagram {
          background-image: url(${instagramIcon});
        }

        &--twitter {
          background-image: url(${twitterIcon});
        }

        &--linkedin {
          background-image: url(${linkedinIcon});
        }
      }
    }
  }

  .dzoni-pent {
    color: #c0c0c0;
    font-size: 14px;
  }
`;

const ComingSoonStyled = styled(ComingSoonButton)`
  margin-bottom: 33px;
`;

export default Footer;
