import React from "react";
import styled from "styled-components";
import appStoreImg from "../images/appstore.svg";
import playStoreImg from "../images/playstore.svg";

const ComingSoonButton = ({ className }) => {
  return <Wrapper className={className}>COMING SOON</Wrapper>;
};

const Wrapper = styled.button`
  margin-bottom: 105px;
  padding: 11px 19px 11px 80px;
  letter-spacing: 1.5px;
  background-color: white;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 6px;
  background-image: url(${appStoreImg}), url(${playStoreImg});
  background-repeat: no-repeat;
  background-position: 19px center, 53px center;
  background-size: 18.9px 23.2px, 18.1px 20.2px;
  max-width: 271px;
  font-size: 14px;

  @media screen and (max-width: 875px) {
    margin: 0 auto 65px auto;
  }
`;

export default ComingSoonButton;
