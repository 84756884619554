/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
     img {
	 margin: 0;
     }
     body{
       font-family: -apple-system, BlinkMacSystemFont, sans-serif;
       overflow-y: hidden;
     }
 
   * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }
 
   h1,
   a,
   p {
     color: white;
   }
 
   button {
     border: none;
     background: none;
   }
 
   a,
   button {
     outline: none;
   }
   
   ul,
   li {
     list-style: none;
     margin: 0;
     padding: 0;
   }
 
   a {
     text-decoration: none;
   }
 
 `;

const Main = styled.main`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <GlobalStyle />
      <Main>{children}</Main>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
