import React from "react";

import styled from "styled-components";
import logo from "../images/logo.svg";
import { Link } from "gatsby";
import rightArrow from "../images/right-arrow.svg";

const AssetsButton = styled.a`
  border-radius: 6px;
  padding: 12px 52px 12px 24px;
  border: none;
  transition: all 0.1s ease-in-out;
  color: white;
  font-weight: 500;

  background-color: rgba(255, 255, 255, 0.3);
  background-image: ${rightArrow};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 6px 12px;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

const HiringButton = styled.a`
  border-radius: 6px;
  padding: 4px 14px;
  border: none;
  transition: all 0.1s ease-in-out;
  color: white;
  font-weight: 500;

  background: rgba(255, 255, 255, 0.3);

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.4);
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 85px;

  @media screen and (max-width: 650px) {
    margin-bottom: 55px;
  }
`;

const Navigation = styled.nav`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 520px) {
    justify-content: flex-start;
  }
`;

const Container = styled.div`
  width: 1200px;
  margin-bottom: 200px;

  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 20px 20px;
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 50px;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    padding: 8px;
  }
`;

const Heading = styled.h1`
  max-width: 450px;
  font-size: 42px;
  margin: 0;

  @media screen and (max-width: 875px) {
    font-size: 38px;
  }

  @media screen and (max-width: 360px) {
    font-size: 32px !important;
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: 12px;
  }
`;

const RightArrowIcon = (props) => (
  <svg
    enable-background="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="m506.13 241.84c-6e-3 -6e-3 -0.011-0.013-0.018-0.019l-104.5-104c-7.829-7.791-20.492-7.762-28.285 0.068-7.792 7.829-7.762 20.492 0.067 28.284l70.164 69.824h-423.56c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.56l-70.162 69.824c-7.829 7.792-7.859 20.455-0.067 28.284 7.793 7.831 20.457 7.858 28.285 0.068l104.5-104c6e-3 -6e-3 0.011-0.013 0.018-0.019 7.833-7.818 7.808-20.522-1e-3 -28.314z" />
  </svg>
);

const StyledArrowIcon = styled(RightArrowIcon)`
  position: absolute;
  right: 16px;
  top: 2px;
  width: 22px;
  hegiht: 10px;

  path {
    fill: white;
  }
`;

const PressHeroSection = () => {
  return (
    <Wrapper>
      <Container>
        <Header>
          <Link to="/">
            <img className="logo" src={logo} />
          </Link>

          <Navigation>
            <List>
              <li>
                <Link href="/">Home</Link>
              </li>

              <li>
                <a href="mailto:zivanovic.b1@hotmail.com">Contact Us</a>
              </li>

              <li>
                <HiringButton target="_blank" href="https://angel.co">
                  We're Hiring!
                </HiringButton>
              </li>
            </List>
          </Navigation>
        </Header>

        <div
          css={`
            margin-bottom: 52px;
          `}
        >
          <Heading
            className="left__heading"
            css={`
              margin-bottom: 12px;
            `}
          >
            For press enquiries,
          </Heading>

          <Heading className="left__heading">press@listty.app</Heading>
          <div
            css={`
              margin-top: 8px;
              max-width: 325px;
              width: 100%;
              height: 1px;
              background: white;

              @media screen and (max-width: 352px) {
                display: none;
              }
            `}
          ></div>
        </div>

        <Row
          css={`
            margin-bottom: 6px;
          `}
        >
          <div
            css={`
              position: relative;
            `}
          >

          <AssetsButton
            target="_blank"
            href="https://drive.google.com/drive/folders/1h6jNZZu_STSAJsW8qz3k9NOiK17pd4hG?usp=sharing"
          >
            Get Press Assets
          </AssetsButton>

          <StyledArrowIcon />

          </div>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 875px) {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .logo {
    @media screen and (max-width: 520px) {
      display: none;
    }
  }

  .left {
    max-width: 520px;

    .logo {
      @media screen and (max-width: 875px) {
        height: 45px;
      }
    }

    @media screen and (max-width: 875px) {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }

    @media screen and (max-width: 4 0px) {
      width: 100%;
    }

    &__heading {
      font-size: 42px;
      color: white;

      @media screen and (max-width: 875px) {
        font-size: 38px;
      }
    }

    &__text {
      font-size: 18px;
      margin-bottom: 42px;
    }

    &__links {
      display: flex;
      margin-bottom: 24px;

      &--mobile {
        display: none;

        @media screen and (max-width: 475px) {
          width: 80% !important;
          margin-left: 0;
        }
        @media screen and (max-width: 875px) {
          width: 300px;
          display: flex !important;
          justify-content: space-between;

          > * {
            margin-right: 0 !important;
          }
        }
      }
      @media screen and (max-width: 875px) {
        display: none;
      }
      > * {
        margin-right: 50px;
      }
    }
  }

  .right {
    &__img {
      max-width: 446px;
      width: 100%;

      max-height: 681px;
      height: 100%;

      @media screen and (max-width: 875px) {
        max-height: 573px;
        margin-bottom: 70px;
      }
    }
  }
`;

export default PressHeroSection;
