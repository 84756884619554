import * as React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import Layout from "../components/Layout";
import PressHeroSection from "../components/PressHeroSection";
import SEO from "../components/Seo";

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  .container {
    flex: 1;
    width: 1200px;
    margin: 0 auto;
    padding-top: 65px;

    @media screen and (max-width: 1200px) {
      width: 100%;
      padding: 16px;
    }

    @media screen and (max-width: 400px) {
      width: 100%;
      padding: 8px;
    }
  }

  .black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    z-index: -2;
    width: 100%;
    height: 100%;
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 1642px;
    background: linear-gradient(
      175.08deg,
      #0046ce 2.5%,
      rgba(0, 0, 0, 0) 86.02%
    );
  }
`;

const Press = () => {
  return (
    <Wrapper>
      <SEO title="Press" />
      <Layout>
        <div className="black-overlay"></div>
        <div className="gradient-overlay"></div>
        <div className="container">
          <PressHeroSection />
        </div>
        <Footer />
      </Layout>
    </Wrapper>
  );
};

export default Press;
